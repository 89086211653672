import React from "react"
import { Router } from "@reach/router"
import { Link } from "gatsby";
import styled from '@emotion/styled';
import DashboardCard from "../components/dashboard-card/dashboard-card.component.jsx";
import Kitchen from "../components/Kitchen";
import "../components/dashboard-card/dashboard.scss"
import { login } from '../utils/auth';
import dashboardLogo from "../assets/dashboard-logo.svg";
import search from "../assets/search.svg";
import menu from "../assets/menu-icon.svg";
import filter from "../assets/filter.svg";
import newTab from "../assets/new.svg";
import add from "../assets/add.svg";
import remainingImg from "../assets/remaining.svg";
import consumedImg from "../assets/consumed.svg";
import customerEngagement from "../assets/customer-engagement.svg"
import operationsExcellence from "../assets/operations-excellence.svg"
import employeesProductivity from "../assets/employees-productivity.svg"
import actionableInsights from "../assets/actionable-insights.svg"
import { breakpoints, colors, fonts, radius, spacing, animations } from '../utils/styles';
import { isAuthenticated } from '../utils/auth';
import UserContext from '../context/UserContext';
import PrivateRoute from '../components/PrivateRoute';
import Profile from '../components/Profile';
import InterfaceContext, {
  defaultInterfaceContext
} from '../context/InterfaceContext';
import {
  TextContainer,
  Text as BaseText
} from '../components/shared/Typography';


export const dashboardData = [
  {
    imageUrl:customerEngagement,
    title:"Customer Engagement",
    subtitle:"Create Your Own Mobile App",
    dtsu:"100",
    newtabStyle:{
      width: "30px",
      height: "30px",
    },
    newtabImg:newTab,
    addStyle:{
      width: "30px",
      height: "30px",
    },
    addImg:add
  },
  {
    imageUrl:actionableInsights,
    title:"Actionable Insighets",
    subtitle:"Create Your Data Agents ",
    dtsu:"100",
    newtabStyle:{
      width: "30px",
      height: "30px",
    },
    newtabImg:newTab,
    addStyle:{
      width: "30px",
      height: "30px",
    },
    addImg:add
  },
  {
    imageUrl:employeesProductivity,
    title:"Employees Productivity",
    subtitle:"KPI Builder",
    dtsu:"50",
    newtabStyle:{
      width: "30px",
      height: "30px",
    },
    newtabImg:newTab,
    addStyle:{
      width: "30px",
      height: "30px",
    },
    addImg:add
  },
  {
    imageUrl:operationsExcellence,
    title:"Operations Excellence",
    subtitle:"Your Operations Software",
    dtsu:"100",
    newtabStyle:{
      width: "30px",
      height: "30px",
    },
    newtabImg:newTab,
    addStyle:{
      width: "30px",
      height: "30px",
    },
    addImg:add
  },
]
export const userData = {
    remaining:"200",
    consumed:"350"
  }

  const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: none;
`;

const Message = styled.h1`
  color: ${colors.darkest};
  font-family: ${fonts.heading};
`;

const IndexPage = () => {
  
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }
  
  return (
 <>
   <Router>
         <PrivateRoute path="/" component={Profile} />  
       </Router>
 

    <div className="dashboard-page">
        <header className="dashboard-header">
          <Link className="logo-parent" to="/">
            <img className="logo-img" src={dashboardLogo} alt="logo"/>
          </Link>
          <nav className="dashboard-nav">
            <div className="menu">
               <InterfaceContext.Consumer>
                {({
                  isDesktopViewport,
                  kitchenStatus,
                  toggleKitchen,
                  customerAreaStatus,
                  productImagesBrowserStatus,
                }) => (
                  <>
                              <Kitchen
                        isDesktopViewport={isDesktopViewport}
                        status={kitchenStatus}
                        toggle={toggleKitchen}
                        customerAreaStatus={customerAreaStatus}
                        productImagesBrowserStatus={productImagesBrowserStatus}
                      />
                      
              {/* <img className="menu-img" src={menu} alt="menu"/> */}
            </>
            )}
          </InterfaceContext.Consumer>
          </div>
            <button type="button" aria-label="toggle" className="filter">
            <img className="filter-img" src={filter} alt="filter"/>
            </button>
          </nav>
        </header>
        <h1 className="dashbord-slogan">
        <span>Welcome to</span>
        <span>iCloud Ready Platform</span>
        </h1>
        <div className="dashboard-cards">
            {
              dashboardData.map((data, index) => (
                <DashboardCard key={index}
                  imageUrl={data.imageUrl} 
                  title={data.title} 
                  subtitle={data.subtitle} 
                  dtsu={data.dtsu} 
                  newtabStyle={data.newtabStyle} 
                  newtabImg={data.newtabImg} 
                  addStyle={data.addStyle} 
                  addImg={data.addImg}
                  ></DashboardCard>
              ))
            }
        </div>
        <footer className="dashboard-footer">
          <div className="footer-content">
            <div className="remaining">
              <img className="remaining-img" src={remainingImg} alt="remaining"/>
              <span>Remaining DTSUs: </span>
              <span>{userData.remaining}</span>
            </div>
            <div className="consumed">
              <img className="consumed-img" src={consumedImg} alt="consumed"/>
              <span>Consumed DTSUs: </span>
              <span>{userData.consumed}</span>
            </div>
          </div>
        </footer>
    </div>
    </>
);
};

export default IndexPage
