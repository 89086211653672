import React from "react";
import { Link } from "gatsby";
import styled from '@emotion/styled';
import "./dashboard-card.styles.scss";
import CustomButton from "../custom-button/custom-button.component";
import { breakpoints, colors, fonts, radius, spacing, animations } from '../../utils/styles';


const DESCRIPTION_LIMIT = 90;
const TRANSITION_DURATION = '250ms';

const RequestItemLink = styled(Link)`
 background: ${colors.lightest};
  border-radius: ${radius.large}px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  margin-bottom: ${spacing.lg}px;
  overflow: hidden;
  text-decoration: none;
  transition: all ${TRANSITION_DURATION};

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    flex-basis: 300px;
    justify-content: center;
    margin: ${spacing.md * 1.25}px;
  }

  @media (hover: hover) {
    :hover {
      background: ${colors.brandLighter};
    }
  }
`;

const DashboardCard = ({ imageUrl, title, subtitle, dtsu, newtabStyle, newtabImg, addStyle, addImg }) => {
  return (
    <RequestItemLink to={`/store/`} aria-label={`product`} >
  <div className="card-container">
    <div className="image-parent">
      <img src={imageUrl} className="crad-image" alt="" />
    </div>
    <div className="content-parent">
      <h3 className="title">{title}</h3>
      <h6 className="subtitle">{subtitle}</h6>
      <div className="card-bottom">
          <span className="dtsu">
            <span className="dtsu-value">{dtsu}</span>
            <span className="dtsu-unit">DTSU*</span>
          </span>
          <div className="card-btns">
            <CustomButton className="newtab-btn" style={newtabStyle}>
              <img src={newtabImg} alt="newtab" />.
            </CustomButton>
            <CustomButton className="add-btn" style={addStyle}>
            <img src={addImg} alt="add" />
            </CustomButton>
          </div>
          </div>
    </div>
  </div>
  </RequestItemLink>
)};

export default DashboardCard;
