import React, { useEffect } from "react"
import { login, logout, isAuthenticated, getUserInfo } from "../../utils/auth"
import Loading from '../CustomerArea/Loading';


const PrivateRoute = ({ component: Component, path, ...rest }) => {

  useEffect(() => {
    if (Loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await login({
        appState: {targetUrl: window.location.pathname}
      })
    }
    fn()
  }, [Loading, isAuthenticated, login, path])

  return isAuthenticated === true ? <Component {...rest} /> : null
}

export default PrivateRoute
